export const GITLAB_PROJECT_ID = "27810898";

export const HEADER_LINKS = {
  OVERVIEW: {
    visible: true,
    synthetic: true,
    active: true,
    href: "/admin/activity",
    icon: "Overview",
    text: "Overview",
    productId: null,
  },
  PIM: {
    visible: true,
    synthetic: true,
    active: false,
    href: "/pim2/items",
    icon: "PIM",
    text: "PIM",
    productId: "PIM",
  },
  XM: {
    visible: true,
    synthetic: true,
    active: false,
    href: "/xpm/pages",
    icon: "XM",
    text: "XM",
    productId: "XM",
  },
  OFFERS: {
    visible: true,
    synthetic: true,
    active: false,
    href: "/offers",
    icon: "Offers",
    text: "OFFERS",
    productId: "OFFERS",
  },
  OMS: {
    visible: true,
    synthetic: true,
    active: false,
    href: "/oms/orders",
    icon: "OMS",
    text: "OMS",
    productId: "OMS",
  },
  CDP: {
    visible: true,
    synthetic: true,
    active: false,
    href: "/customer/customers",
    icon: "CSR",
    text: "CDP",
    productId: "CDP",
  },
  MARKETPLACE: {
    visible: true,
    synthetic: true,
    active: false,
    href: "/marketplace/orders",
    icon: "",
    text: "MARKET",
    productId: "MARKET",
  },
};
// Updated header link data for L1 terminology changes
export const HEADER_LINKS_UPDATED = {
  OVERVIEW: {
    href: "/home/activity",
    text: "Home",
    productId: null,
  },
  PIM: {
    href: "/products/items",
    text: "Products",
    productId: "PIM",
  },
  XM: {
    href: "/experiences/pages",
    text: "Experiences",
    productId: "XM",
  },
  OFFERS: {
    href: "/offers",
    text: "Offers",
    productId: "OFFERS",
  },
  OMS: {
    href: "/orders/orders",
    text: "Orders",
    productId: "OMS",
  },
  CDP: {
    href: "/customer/customers",
    text: "Customers",
    productId: "CDP",
  },
  MARKETPLACE: {
    href: "/marketplace/orders",
    text: "Marketplace",
    productId: "MARKET",
  },
};

export const getLinkTextIcon = ({
  productId,
  href,
  isNewTerminologyEnabled,
  isNewL1LinksEnabled,
}) => {
  if (
    productId === null &&
    (href === HEADER_LINKS.OVERVIEW.href ||
      href === HEADER_LINKS_UPDATED.OVERVIEW.href)
  ) {
    return {
      icon: isNewTerminologyEnabled ? "" : HEADER_LINKS.OVERVIEW.icon,
      text: isNewTerminologyEnabled
        ? HEADER_LINKS_UPDATED.OVERVIEW.text
        : HEADER_LINKS.OVERVIEW.text,
      href:
        isNewTerminologyEnabled && isNewL1LinksEnabled
          ? HEADER_LINKS_UPDATED.OVERVIEW.href
          : HEADER_LINKS.OVERVIEW.href,
    };
  }
  if (href === "/rtpe/prices") {
    return {
      icon: isNewTerminologyEnabled ? "" : HEADER_LINKS.OFFERS.icon,
      text: isNewTerminologyEnabled
        ? HEADER_LINKS_UPDATED.OFFERS.text
        : HEADER_LINKS.OFFERS.text,
      href,
    };
  }
  const baseArray = isNewTerminologyEnabled
    ? [
        HEADER_LINKS_UPDATED.OVERVIEW,
        HEADER_LINKS_UPDATED.PIM,
        HEADER_LINKS_UPDATED.XM,
        HEADER_LINKS_UPDATED.OFFERS,
        HEADER_LINKS_UPDATED.OMS,
        HEADER_LINKS_UPDATED.CDP,
        HEADER_LINKS_UPDATED.MARKETPLACE,
      ]
    : initConfig.links;
  const filteredItem = baseArray.filter(
    (item) => item.productId === productId,
  )[0];
  const iconFilteredItem = initConfig.links.filter(
    (item) => item.productId === productId,
  )[0];
  return {
    icon: isNewTerminologyEnabled ? "" : iconFilteredItem.icon,
    text: filteredItem?.text || "",
    href: isNewL1LinksEnabled ? filteredItem?.href : iconFilteredItem.href,
  };
};

const initConfig = {
  company: "fabric",
  logo: {
    height: "24px",
    image: "",
    width: "24px",
  },
  showNotification: false,
  notifications: [
    {
      type: "notification",
      day: "yesterday",
      text: "<span>The Mari Bag (0200388292)</span> was assigned to you and needs your approval.",
      actionLabel: "Review Item",
      clickHandler: () => {},
    },
    {
      type: "notification",
      day: "today",
      text: "<span>The Mari Bag (0200388292)</span> was assigned to you and needs your approval.",
      actionLabel: "Review Item",
      clickHandler: () => {},
    },
    {
      type: "mention",
      day: "yesterday",
      text: "Hi <span>@user</span> we need your updated card data.",
      actionLabel: "Review Item",
      clickHandler: () => {},
    },
    {
      type: "update",
      day: "today",
      text: "<span>@user</span> some new features are deployed.",
      actionLabel: "Review Item",
      clickHandler: () => {},
    },
    {
      type: "update",
      day: "yesterday",
      text: "<span>@user</span> new debit card rules starting tomorrow.",
      actionLabel: "Review Item",
      clickHandler: () => {},
    },
  ],
  links: [
    HEADER_LINKS.OVERVIEW,
    HEADER_LINKS.PIM,
    HEADER_LINKS.XM,
    HEADER_LINKS.OFFERS,
    HEADER_LINKS.OMS,
    HEADER_LINKS.CDP,
    HEADER_LINKS.MARKETPLACE,
  ],
  user: {
    userEmail: "",
    userName: "",
    userNav: [
      {
        label: "Edit Profile",
        link: "/auth/my-account",
      },
      {
        label: "Logout",
        link: "/auth/login",
      },
    ],
  },
};

export default initConfig;
